import React from "react";
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap'
import Rennan from './assets/img/rennan.jpg'
import Logo from './assets/img/logo.png'
import Icon from './assets/img/icon.png'
import Clientes from './assets/img/clientes.png'
import Rennan2 from './assets/img/rw202850-1@1x.png'
import LogoRoxo from './assets/img/icon-3@2x.png'
import IconWhite from './assets/img/iconWhite.png'
import Iconsax from './assets/img/iconsax-linear-addcircle-1@2x.svg'
import HIHIBranco from './assets/img/branco-logt-1@2x.png'
import Facebook from './assets/img/iconsax-linear-facebook@2x.svg'
import Instagram from './assets/img/iconsax-linear-instagram@2x.svg'
import Linksquare from './assets/img/iconsax-linear-linksquare@2x.svg'
import FeedBack2 from './assets/img/image-2@2x.png'
import { ImWhatsapp } from 'react-icons/im'
import './App.css'
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      icon: LogoRoxo,
      iconOne: Icon,
      style7: 'rectangle-7',
      style2: '',
      style3: '',
      style4: '',
      colorIcon: '',
      numero: '',
      numero2: '',
      numero3: '',
      numero4: '',
      fullName: '',
      email: '',
      whatsapp: '',
      instagram: '',
      cep: '',
      address: '',
      comunidade:true,
    }
  }
  iconWhiteChange = () => {
    this.setState({ icon: IconWhite })
  }
  iconChange = () => {
    this.setState({ icon: LogoRoxo })
  }
  iconWhiteChangeOne = () => {
    this.setState({ iconOne: IconWhite, colorIcon: 'colorIconModificado' })
  }
  iconChangeOne = () => {
    this.setState({ iconOne: Icon, colorIcon: '' })
  }
  //number-1
  styleChange7 = () => {
    this.setState({ style7: 'rectangle-7Modificado', numero: 'number-modificado' })
  }
  styleReturn7 = () => {
    this.setState({ style7: 'rectangle-7', numero: '' })
  }
  //number-2
  styleChange2 = () => {
    this.setState({ style2: 'rectangle-Modificado', numero2: 'number-modificado' })
  }
  styleReturn2 = () => {
    this.setState({ style2: '', numero2: '' })
  }
  //number-3
  styleChange3 = () => {
    this.setState({ style3: 'rectangle-Modificado', numero3: 'number-modificado' })
  }
  styleReturn3 = () => {
    this.setState({ style3: '', numero3: '' })
  }
  //number-4
  styleChange4 = () => {
    this.setState({ style4: 'rectangle-Modificado', numero4: 'number-modificado' })
  }
  styleReturn4 = () => {
    this.setState({ style4: '', numero4: '' })
  }
  handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", this.state.fullName);
    formData.append("email", this.state.email);
    formData.append("whatsapp", this.state.whatsapp);
    formData.append("instagram", this.state.instagram);
    formData.append("cep", this.state.cep);
    formData.append("endereco", this.state.address);

    fetch("/form.php", {
      method: "POST",
      body: formData
    })
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log(error));
    this.setState({comunidade:false})
  }
  render() {
    const handleClick = () => {
      window.gtag('config', 'AW-XXXXXXXXXX');
      window.gtag('event', 'conversion', { 'send_to': 'AW-11078728477/TWxLCJnXwokYEJ3236Ip' });
    }
    return (
      <>
        <Container id="Home">
          <Row>
            <Col>
              <img className="logotipo-1" src={Logo} alt="logotipo 1" />


              <div className="o-que-o-que-or h1---montserrat-bold">
                <span className="span0-1 h1---montserrat-bold">
                  O QUE É, O QUE É?<br />
                </span>

                <span className="span1-1 h1---montserrat-bold">
                  Orienta, mas não é bússola; Não é anjo, mas cuida;
                  Não é foguete, mas faz lançamento; Não é engenheiro, mas estrutura?
                </span>
                <div className="tenho-certeza-que-at h2---montserrat-medium">
                  Tenho certeza que você vai se surpreender com tudo que essa HIHIGO! pode fazer por você!
                </div>
              </div>
            </Col>
            <Col>
              <img className="rw202827-2" src={Rennan} />
            </Col>
          </Row>
          <div className="resumindo-implement h2---montserrat-medium">
            Resumindo: Implementamos o setor de marketing dentro da sua empresa.
          </div>
          <Container>
            <Row className='caixas-pc'>
              <Col className="background-number col-number-1">
                <Row>
                  <Col>
                    <div className='number-1'>
                      1
                    </div>
                  </Col>
                  <Col>
                    <div className='number-text h2---montserrat-medium'>
                      Fazemos <br />
                      gestão de<br />
                      marca
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="background-number col-number-2">
                <Row>
                  <Col>
                    <div className='number-2'>
                      2
                    </div>
                  </Col>
                  <Col>
                    <div className='number-text h2---montserrat-medium'>
                      Criação de <br />
                      materiais <br />
                      Visuals
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="background-number col-number-3">
                <Row>
                  <Col>
                    <div className='number-3'>
                      3
                    </div>
                  </Col>
                  <Col>
                    <div className='number-text h2---montserrat-medium'>
                      Tráfego pago, desenvolvimento <br />e programação
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="background-number col-number-4"> <Row>
                <Col>
                  <div className='number-4'>
                    4
                  </div>
                </Col>
                <Col>
                  <div className='number-text h2---montserrat-medium'>
                    Criação <br />
                    de site e <br />
                    aplicativos
                  </div>
                </Col>
              </Row>
              </Col>
            </Row>
            {/* mobile */}
            <Row className='mobile-caixas' style={{ display: "none" }}>
              <Col className="background-number col-number-1">
                <Row>
                  <Col>
                    <div className='number-1'>
                      1
                    </div>
                  </Col>
                  <Col>
                    <div className='number-text h2---montserrat-medium'>
                      Fazemos <br />
                      gestão de<br />
                      marca
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="background-number col-number-2">
                <Row>
                  <Col>
                    <div className='number-2'>
                      2
                    </div>
                  </Col>
                  <Col>
                    <div className='number-text h2---montserrat-medium'>
                      Criação de <br />
                      materiais <br />
                      Visuals
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="background-number col-number-3">
                <Row>
                  <Col>
                    <div className='number-3'>
                      3
                    </div>
                  </Col>
                  <Col>
                    <div className='number-text h2---montserrat-medium'>
                      Tráfego pago, desenvolvimento <br />e programação
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="background-number col-number-4"> <Row>
                <Col>
                  <div className='number-4'>
                    4
                  </div>
                </Col>
                <Col>
                  <div className='number-text h2---montserrat-medium'>
                    Criação <br />
                    de site e <br />
                    aplicativos
                  </div>
                </Col>
              </Row>
              </Col>
            </Row>

            <div className="container-principal-redes" >
              <div className={"siga-nos-nas-redes-sociais " + this.state.colorIcon} onMouseOver={() => this.iconWhiteChangeOne()} onMouseLeave={() => this.iconChangeOne()}>
                SIGA-NOS NAS<br />REDES SOCIAIS</div>
              <a href="https://www.instagram.com/instahihigo/" target="_blank"><img className={this.state.colorIcon + " icon-instagram"} src={Instagram} alt="icon-instagram" onMouseOver={() => this.iconWhiteChangeOne()} onMouseLeave={() => this.iconChangeOne()} /></a>
              <a href="https://ms-my.facebook.com/instahihigo/" target="_blank"><img className={this.state.colorIcon + " icon-facebook"} src={Facebook} alt="icon-facebook" onMouseOver={() => this.iconWhiteChangeOne()} onMouseLeave={() => this.iconChangeOne()} /></a>
              <a href="https://api.whatsapp.com/send/?phone=5521969868671&app_absent=0" target="_blank"><img className={this.state.colorIcon + " iconsax-linearlinksquare"} src={Linksquare} alt="Iconsax/Linear/linksquare" onMouseOver={() => this.iconWhiteChangeOne()} onMouseLeave={() => this.iconChangeOne()} /></a>
              <img className="icon-1" src={this.state.iconOne} alt="icon" onMouseOver={() => this.iconWhiteChangeOne()} onMouseLeave={() => this.iconChangeOne()} />
            </div>
          </Container >

        </Container>
        <Container fluid style={{ "backgroundColor": "#e6e6e6" }} id="About">
          <div className="todo-servio-perso h1---montserrat-bold" >
            Todo serviço é personalizado e essa personalização vai de acordo com o
            que seu negócio precisa!
          </div>

          <div className="hihigo montserrat-bold-white-96px">
            HIHIGO<br />
            HIHIGO<br />
            HIHIGO<br />
            HIHIGO<br />
            HIHIGO<br />
            HIHIGO<br />
            HIHIGO<br />
          </div>

          <Row>
            <Col>
              <div className="voc-deve-estar-se-perguntando h3-montserrat-regular">
                Você deve estar se perguntando:
              </div>
              <div className="porque-ter-um-setor h1---montserrat-bold">
                Porque ter um setor de marketing na minha empresa gerenciado pela HIHIGO!
              </div>
              <Row className='row-screen2'>
                <Col xs={4} style={{ "marginLeft": "9%" }}>
                  <div className='hihigo-screen2 h1---montserrat-bold'>HIHIGO!</div>
                </Col>
                <Col xs={1}>
                  <div className='igual h1---montserrat-bold'>=</div>
                </Col>

                <Col xs={1}>
                  <div className="muito-trabalho-e-competncia h3-montserrat-regular">
                    Muito trabalho<br />e competência.
                  </div>

                </Col>


              </Row>

            </Col>
            <Col>
              <div className="autenticidade-trata h2---montserrat-medium">
                <span className="autenticidade h2---montserrat-medium">
                  Autenticidade<br />
                </span>
                <span className="span1 h2---montserrat-medium">
                  Trata-se da linguagem que queremos ter com os nossos <br />
                  parceiros: Um serviço de qualidade, único e personalizado.
                </span>
              </div>
              <hr></hr>
              <div className=" entusiasmo-sua-marca h2---montserrat-medium">
                <span className="entusiasmo h2---montserrat-medium">
                  Entusiasmo<br />
                </span>

                <span className="span1 h2---montserrat-medium">
                  Sua marca torna-se nosso foco onde nossos estudos são voltados
                  exclusivamente para o sucesso da sua empresa e juntos
                  vibramos com cada meta alcançada.
                </span>
              </div>
              <hr></hr>
              <div className=" conexo-acreditamos h2---montserrat-medium">
                <span className="conexo h2---montserrat-medium">
                  Conexão<br />
                </span>
                <span className="span1 h2---montserrat-medium">
                  Acreditamos que uma equipe engajada com seus parceiros trazem
                  resultados incríveis. Uma comunicação eficaz faz toda
                  diferença!
                </span>
              </div>
            </Col>
          </Row>
          <div className="feedBack-container">
            {/* <Row>
              <Col>
                
                <img className="image-2" src={FeedBack2} alt="image 2" />
              </Col>
              <Col>  <div className="feedbacks-uma-empres h2---montserrat-medium">
                <span className="h2---montserrat-medium">
                  Feedbacks<br />
                </span>
                <span className="span1 h2---montserrat-medium">
                  Uma empresa admirável, que fez tudo se encaixar e me trouxe uma
                  nova visão de empreendedorismo!
                </span>
                <Row align="center" style={{ "marginLeft": "30%", "marginTop": "1%" }}>
                  <Col xs={1}>
                    <div className="elipse-1"></div>
                  </Col>
                  <Col xs={1}>
                    <div className="elipse-2"></div>
                  </Col>
                  <Col xs={1}>
                    <div className="elipse-1"></div>
                  </Col>
                </Row>



              </div>
              </Col>

            </Row> */}


          </div>
        </Container>
        <Container fluid id="Services">
          <Row>
            <Col xs={4}>

              <div className="confira-nossos-clientes-de-sucesso h1---montserrat-bold">
                Confira nossos<br />
                clientes de sucesso
              </div>
              <div className="algumas-marcas-que-s h2---montserrat-medium">
                Algumas marcas que<br />
                somamos forças nessa<br />
                jornada.
              </div>
              <img className="icon-2" src={Icon} alt="icon 2" />
            </Col>
            <Col><img className="clientes-1" src={Clientes} alt="clientes 1" /></Col>
          </Row>
        </Container>
        <Container fluid style={{ "backgroundColor": "#ec0973" }}>
          <Row>
            <Col>
              <div className="hihigo montserrat-bold-white-96px">
                HIHIGO<br />
                HIHIGO<br />
                HIHIGO<br />
                HIHIGO<br />
                HIHIGO<br />
                HIHIGO<br />
                HIHIGO<br />
              </div>
              <div className="saia-na-frente-com-a-hihigo h1---montserrat-bold">
                Saia na frente<br />
                com a HIHIGO!
              </div>
              <div className="autoridade-da-marca valign-text-middle h2---montserrat-medium">

                <img className="iconsax-linearaddcircle" src={Iconsax} alt="iconsax-linearaddcircle" />&nbsp;&nbsp;&nbsp;&nbsp;Autoridade da marca<br />
                <img className="iconsax-linearaddcircle" src={Iconsax} alt="iconsax-linearaddcircle" />&nbsp;&nbsp;&nbsp;&nbsp;Presença online<br />
                <img className="iconsax-linearaddcircle" src={Iconsax} alt="iconsax-linearaddcircle" />&nbsp;&nbsp;&nbsp;&nbsp;Engajamento<br />
                <img className="iconsax-linearaddcircle" src={Iconsax} alt="iconsax-linearaddcircle" />&nbsp;&nbsp;&nbsp;&nbsp;Fidelização de clientes<br />
                <img className="iconsax-linearaddcircle" src={Iconsax} alt="iconsax-linearaddcircle" />&nbsp;&nbsp;&nbsp;&nbsp;Vendas a longo personalizado<br />
                <img className="iconsax-linearaddcircle" src={Iconsax} alt="iconsax-linearaddcircle" />&nbsp;&nbsp;&nbsp;&nbsp;Perfil mais profissional
              </div>
            </Col>
            <Col>
              <div className='rw202850'>
                <div className="queremos-te-desejar h1---montserrat-bold">
                  <span className="span0-3 h1---montserrat-bold">Queremos te desejar as
                    boas vindas a HIHIGO! o mais breve possível. </span>
                  <span className="span1-3 h2---montserrat-medium">Entre em contato com
                    o nosso time de consultores agora mesmo.</span>
                </div>
                <a href="https://api.whatsapp.com/send/?phone=5521969868671&app_absent=0" target="_blank"><button className='button-time h1---montserrat-bold' onClick={handleClick}> Fale com nosso time</button></a>
                <img className="icon-3" src={this.state.icon} alt="icon 3" onMouseOver={() => this.iconWhiteChange()} onMouseLeave={() => this.iconChange()} />
                <img className="rw202850-1" src={Rennan2} alt="rw202850 1" />
              </div>
            </Col>
          </Row>

        </Container>
        <Container fluid style={{ "backgroundColor": "#fff" }} id="Formulario">
          
      <Row className="formulario-comunidade">
        <Col align="center" style={{"margin-top":"13%"}}>
          <h3>Entre agora mesmo para nossa comunidade<br></br> e venha nos conhecer!</h3>
        </Col>
        <Col>
        <Card style={{"margin-top":"2%","padding":"5%","margin":"5%","backgroundColor":"rgba(236,9,115)","color":"white"}}>
      <Form onSubmit={(event) => this.handleSubmit(event)}>
      <Form.Group controlId="formFullName">
        <Form.Label>Nome completo</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite seu nome completo"
          value={this.state.fullName}
          onChange={(event) => this.setState({fullName:event.target.value})}
        />
      </Form.Group>

      <Form.Group controlId="formEmail">
        <Form.Label>E-mail</Form.Label>
        <Form.Control
          type="email"
          placeholder="Digite seu e-mail"
          value={this.state.email}
          onChange={(event) => this.setState({email:event.target.value})}
        />
      </Form.Group>

      <Form.Group controlId="formWhatsapp">
        <Form.Label>WhatsApp</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite seu número de WhatsApp"
          value={this.state.whatsapp}
          onChange={(event) => this.setState({whatsapp:event.target.value})}
        />
      </Form.Group>

      <Form.Group controlId="formInstagram">
        <Form.Label>Instagram</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite seu nome de usuário do Instagram"
          value={this.state.instagram}
          onChange={(event) => this.setState({instagram:event.target.value})}
        />
      </Form.Group>

      <Form.Group controlId="formCep">
        <Form.Label>CEP</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite seu CEP"
          value={this.state.cep}
          onChange={(event) => this.setState({cep:event.target.value})}
        />
      </Form.Group>

      <Form.Group controlId="formAddress">
        <Form.Label>Endereço</Form.Label>
        <Form.Control
          type="text"
          placeholder="Digite seu endereço completo"
          value={this.state.address}
          onChange={(event) => this.setState({address:event.target.value})}
        />
      </Form.Group>
           <div align="center">
      <Button variant="primary" type="submit" style={{"margin-top":"5%"}} >
        
        Enviar
      </Button>
      <a href="https://chat.whatsapp.com/CLOQpcqvcAb8YHt5Uhj16Z" style={{"margin-left":"3%"}} hidden={this.state.comunidade} target="_blank"><Button variant="warning"  style={{"margin-top":"5%"}} >Entrar na Comunidade</Button></a>
      </div>
    </Form>
    </Card>
    </Col> 
        </Row>   
        </Container>
        <Container fluid style={{ "backgroundColor": "#000" }} id="Contact">
          <Row align="center" >
            <Col className="no-mobile"><img className="branco-logt-1" src={HIHIBranco} alt="BRANCO LOGT 1" /></Col>
            <Col style={{ "marginTop": "1%" }}>
              <div className="home-about-serv1">
                <span className="span0-2">
                  <a href="#Home" style={{ 'textDecoration': 'none', 'color': 'white' }}>Home</a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href='#About' style={{ 'textDecoration': 'none', 'color': 'white' }}>Sobre</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href='#Services' style={{ 'textDecoration': 'none', 'color': 'white' }}>Serviços</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href='#Contact' style={{ 'textDecoration': 'none', 'color': 'white' }}>Contato</a>
                  <br />
                </span>
                <span className="span1-2">Copyright 2022, HIHIGO. All Rights Reserved.</span>
              </div>
            </Col>
            <Col style={{ "display": "none" }} className="mobile"><img className="branco-logt-1" src={HIHIBranco} alt="BRANCO LOGT 1" /></Col>
            <Col style={{ "marginTop": "1%" }}>
              <Row >
                <Col xs={5} style={{ "marginLeft": "20%" }}>
                  <div className="h1---montserrat-bold" style={{ "color": "white" }}>
                    SIGA-NOS NAS
                    <br />
                    REDES SOCIAIS
                  </div>
                </Col>
                <Col xs={3}>
                  <a href="https://www.instagram.com/instahihigo/" target="_blank"><img className="icon-instagram-1" src={Instagram} alt="icon-instagram" />&nbsp;&nbsp;</a>
                  <a href="https://ms-my.facebook.com/instahihigo/" target="_blank"><img className="icon-facebook-1" src={Facebook} alt="icon-facebook" />&nbsp;&nbsp;</a>
                  <a href="https://api.whatsapp.com/send/?phone=5521969868671&app_absent=0" target="_blank"><img className="iconsax-linearlinksquare-1" src={Linksquare} alt="Iconsax/Linear/linksquare" /></a>
                </Col>


              </Row>
            </Col>
          </Row>

        </Container>
      </>


    );
  }
}

export default App;
